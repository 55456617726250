<template>
  <v-card flat>
    <h5>Continue as Mediapal Platform?</h5>
    <p class="text-body-2 text--disabled mt-2 mb-9">
      MediaPal will receive your name and profile picture. This doesn’t let
      MediaPal post to Facebook without your permission.
    </p>
    <v-card-actions class="d-flex justify-space-between">
      <v-btn small width="190" @click="$emit('cancel')">Cancel</v-btn>
      <v-btn color="#0075FB" dark small width="190" @click="$emit('next')"
        >Continue as Mediapal Platform</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {

}
</script>