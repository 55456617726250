<template>
  <v-card flat>
    <h5>
      Which Instagram Business accounts do you want to use with Social Presence
      Manager?
    </h5>
    <p class="text-body-2 text--disabled mt-2 mb-9">
      In a later step, you will determine what Social Presence Manager can do
      with the accounts you’ve selected.
    </p>

    <v-list subheader two-line flat>
      <span class="d-flex justify-space-between align-center">
        <v-subheader>All Instagram Business accounts ({{ accounts.length }})</v-subheader>
        <v-checkbox
          class="mr-2"
          @click="checkAll()"
          v-model="isCheckAll"
        ></v-checkbox>
      </span>
      <v-divider></v-divider>
      <span v-for="account in accounts" :key="account">
        <v-list-item>
          <template>
            <v-list-item-content>
              <v-list-item-title class="text-body-2">{{
                account
              }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-checkbox
                color="primary"
                :value="account"
                v-model="checked_accounts"
                @change="updateCheckall()"
              ></v-checkbox>
            </v-list-item-action>
          </template>
        </v-list-item>
      </span>
    </v-list>

    <v-card-actions class="d-flex justify-space-between mt-8">
      <v-btn small @click="$emit('cancel')">Cancel</v-btn>
      <v-btn color="#0075FB" dark small @click="$emit('next')">Next</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    isCheckAll: false,
    accounts: ["Mediapal Platform", "Gates Foundation", "Bidco", "Royal Media"],
    checked_accounts: [],
  }),
  methods: {
    checkAll() {
      this.checked_accounts = [];
      if (this.isCheckAll) {
        this.accounts.forEach((item, index) =>
          this.checked_accounts.push(this.accounts[index])
        );
      }
    },
    updateCheckall() {
      if (this.checked_accounts.length == this.accounts.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
  },
};
</script>
