<template>
  <v-card flat>
    <h5>What Pages do you want to use with MediaPal?</h5>
    <p class="text-body-2 text--disabled mt-2 mb-9">
      In the next step, you’ll determine what MediaPal can do with the Pages
      you’ve selected.
    </p>

    <v-list subheader two-line flat>
      <span class="d-flex justify-space-between align-center">
        <v-subheader>All Pages({{ pages.length }})</v-subheader>
        <v-checkbox
          class="mr-2"
          @click="checkAll()"
          v-model="isCheckAll"
        ></v-checkbox>
      </span>
      <v-divider></v-divider>
      <span v-for="page in pages" :key="page">
        <v-list-item>
          <template>
            <v-list-item-content>
              <v-list-item-title class="text-body-2">{{
                page
              }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-checkbox
                color="primary"
                :value="page"
                v-model="checked_pages"
                @change="updateCheckall()"
              ></v-checkbox>
            </v-list-item-action>
          </template>
        </v-list-item>
      </span>
    </v-list>

    <v-card-actions class="d-flex justify-space-between mt-8">
      <v-btn small @click="$emit('cancel')">Cancel</v-btn>
      <v-btn color="#0075FB" dark small @click="$emit('next')">Next</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    isCheckAll: false,
    pages: ["Mediapal Platform", "Gates Foundation", "Bidco", "Royal Media"],
    checked_pages: [],
  }),
  methods: {
    checkAll() {
      this.checked_pages = [];
      if (this.isCheckAll) {
        this.pages.forEach((item, index) =>
          this.checked_pages.push(this.pages[index])
        );
      }
    },
    updateCheckall() {
      if (this.checked_pages.length == this.pages.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
  },
};
</script>
