<template>
  <v-btn color="#3B5998" dark large @click="$emit('next')">
    <v-icon left>mdi-facebook</v-icon>
    Continue with Facebook
  </v-btn>
</template>

<script>
export default {

}
</script>