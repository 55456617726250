<template>
  <v-card flat>
    <h5>
      What is Social Presence Manager allowed to do?
    </h5>

    <v-alert elevation="2" type="info" color="#0075FB" colored-border border="left" class="my-5">
      <p class="text-body-2 text--disabled">Social Presence Manager might not work properly if you turn off these
      options.</p>
    </v-alert>

    <p class="text-body-2 text--disabled mt-2">
      Available permissions on MediaPal
    </p>

    <h6>Standard Access</h6>

    <v-list subheader two-line flat>
      <span
        v-for="[permission, direction, more] in permissions"
        :key="permission"
      >
        <v-list-item>
          <template>
            <v-list-item-content>
              <v-list-item-title class="text-body-2">{{
                permission
              }}</v-list-item-title>
              <v-list-item-subtitle
                >{{ direction }} <br />
                <br />

                <span v-if="more">
                  {{ more }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-switch
                :value="permission"
                v-model="checked_permissions"
              ></v-switch>
            </v-list-item-action>
          </template>
        </v-list-item>
      </span>
    </v-list>

    <v-card-actions class="d-flex justify-space-between mt-8">
      <v-btn small @click="$emit('cancel')">Cancel</v-btn>
      <v-btn color="#0075FB" dark small @click="$emit('next')">Next</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    permissions: [
      ["Publish content", "Send messages and publish as the Page."],
      ["Moderate comments", "Moderate comments as the Page."],
      ["Create ads", "Advertise for the Page."],
      ["Send messages", "Send and respond to direct messages as the Page."],
      [
        "View Page performance",
        "View all Facebook Analytics and Page Insights about the Page.",
      ],
      [
        "Manage jobs",
        "Publish and manage jobs, view and manage applications and create ads.",
      ],
      ["View Earnings Insights", "View the earning insights for the Page."],
      [
        "Admin Access",
        "Manage Page",
        "Control the Page and connected Instagram account settings and permissions. Perform any action on the Page, view all Page activity and Performance, and manage Page roles. This is the most access you can grant.",
      ],
    ],
    checked_permissions: [],
  }),
};
</script>

<style scoped>
.v-list-item {
  padding: 0 !important;
}

.v-list-item__title,
.v-list-item__subtitle {
  white-space: pre-wrap;
}
</style>
