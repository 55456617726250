<template>
  <div>
    <v-tabs v-model="facebookTab" background-color="#F5F7FB">
        <v-tab v-for="[tab, value] in tabs" :key="value">
          <span class="text-capitalize">{{ tab }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="facebookTab" style="background: transparent">
        <v-tab-item>
        <campaigns-table
          :headers="facebook_table_headers"
          :campaigns="facebook_campaigns"
          :ad_accounts="ad_accounts"
          :ad_account="ad_account"
          @setAdAccountId="setAdAccountId($event)"
          :loading="loading"
        />
      </v-tab-item>
      <v-tab-item>
        <campaigns-table
          :headers="facebook_table_headers"
          :campaigns="running_campaigns"
          :ad_accounts="ad_accounts"
          :ad_account="ad_account"
          @setAdAccountId="setAdAccountId($event)"
          :loading="loading"
        />
      </v-tab-item>
      <v-tab-item>
        <campaigns-table
          :headers="facebook_table_headers"
          :campaigns="paused_campaigns"
          :ad_account="ad_account"
          :ad_accounts="ad_accounts"
          @setAdAccountId="setAdAccountId($event)"
          :loading="loading"
        />
      </v-tab-item>
      <v-tab-item>
        <campaigns-table
          :headers="facebook_table_headers"
          :campaigns="finished_campaigns"
          :ad_account="ad_account"
          :ad_accounts="ad_accounts"
          @setAdAccountId="setAdAccountId($event)"
          :loading="loading"
        />
      </v-tab-item>
      <v-tab-item>
        <campaigns-table
          :headers="facebook_table_headers"
          :campaigns="draft_campaigns"
          :ad_account="ad_account"
          :ad_accounts="ad_accounts"
          @setAdAccountId="setAdAccountId($event)"
          :loading="loading"
        />
      </v-tab-item>
      <v-tab-item>
        <campaigns-table
          :headers="facebook_table_headers"
          :campaigns="mediapal_social_campaigns"
          :ad_account="ad_account"
          :ad_accounts="ad_accounts"
          @setAdAccountId="setAdAccountId($event)"
          :loading="loading"
        />
      </v-tab-item>
    </v-tabs-items>

    <v-row v-if="loggedIn">
      <v-col cols="12">
        <general-card>
          <v-col cols="2"></v-col>
          <v-col cols="8">
            <div
              class="pa-10 mt-8 d-flex justify-center align-center three-quarter-height"
            >
              <step-one v-if="step == 0" @next="next" />
              <step-two v-if="step == 1" @next="next" @cancel="cancel" />
              <step-three v-if="step == 2" @next="next" @cancel="cancel" />
              <step-four v-if="step == 3" @next="next" @cancel="cancel" />
              <step-five v-if="step == 4" @next="next" @cancel="cancel" />
              <step-six v-if="step == 5" @next="next" />
            </div>
          </v-col>
          <v-col cols="2"></v-col>
        </general-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CampaignsTable from "../../../components/Tables/FacebookCampaignsTable.vue";
import GeneralCard from "../../../components/Layouts/Cards/GeneralCard.vue";
import StepOne from "../../../components/Facebook/Linking/StepOne.vue";
import StepTwo from "../../../components/Facebook/Linking/StepTwo.vue";
import StepThree from "../../../components/Facebook/Linking/StepThree.vue";
import StepFour from "../../../components/Facebook/Linking/StepFour.vue";
import StepFive from "../../../components/Facebook/Linking/StepFive.vue";
import StepSix from "../../../components/Facebook/Linking/StepSix.vue";

import { facebookMixin } from "../../../mixins/facebookMixin";

export default {
  mixins: [facebookMixin],
  data: () => ({
    error: null,
    step: 0,
    facebookTab: null,
    loggedIn: false,
    ad_account: {},
    loading: false,
    tabs: [
      ["All Campaigns", "all"],
      ["Running", "running"],
      ["Paused", "paused"],
      ["Finished", "finished"],
      ["Draft", "draft"],
      ["Tradedesk Social Campaigns", "social"],
    ],
    btns: ["Add Campaign"],
    facebook_table_headers: [
      {
        text: "Campaign Name",
        align: "start",
        value: "name",
        width: "20%",
      },
      { text: "Status", value: "status" },
      { text: "Created", value: "created" },
      { text: "Start Date", value: "start" },
      { text: "End Date", value: "end" },
      { text: "Goal", value: "objective" },
      { text: "Bid Strategy", value: "bid_strategy" },
      // {
      //   text: "Budget",
      //   value: "budget_remaining",
      // },
    ],
  }),
  components: {
    GeneralCard,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
    StepSix,
    CampaignsTable,
  },
  methods: {
    next() {
      if (this.step == 5) {
        this.loggedIn = true;
        this.$router.push("/facebook/campaigns");
      }
      this.step++;
    },
    cancel() {
      this.step = 0;
    },
  },
};
</script>
