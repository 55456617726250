<template>
  <v-card flat>
    <h5>
      You’ve now linked Social Presence Manager to Facebook
    </h5>
    <p class="text-body-2 text--disabled mt-2 mb-9">
      You can update what Social Presence Manager can do in your Business
integration settings. To finish setting up, Social Presence Manager may
require additional steps.
    </p>

    <v-card-actions class="mt-8">
      <v-btn color="#0075FB" dark block @click="$emit('next')">OK</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
  }),
};
</script>
